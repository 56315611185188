import '../pages/accounts.css';

import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu';
import { useReducer, useState } from 'preact/hooks';

import Avatar from '../components/avatar';
import Icon from '../components/icon';
import Link from '../components/link';
import MenuConfirm from '../components/menu-confirm';
import NameText from '../components/name-text';
import { api } from '../utils/api';
import states from '../utils/states';
import store from '../utils/store';

function AccountsMenu() {
  const { masto } = api();
  // Accounts
  const accounts = store.local.getJSON('accounts');
  const currentAccount = store.session.get('currentAccount');
  const moreThanOneAccount = accounts.length > 1;
  const [currentDefault, setCurrentDefault] = useState(0);

  const [_, reload] = useReducer((x) => x + 1, 0);

  return (
    <>
    {accounts.map((account, i) => {
        const isCurrent = account.info.id === currentAccount;
        const isDefault = i === (currentDefault || 0);
        return (
        <MenuItem onClick={() => {
                        if (isCurrent) {
                          states.showAccount = `${account.info.username}@${account.instanceURL}`;
                        } else {
                          store.session.set('currentAccount', account.info.id);
                          location.reload();
                        }
                      }}>
            <div class='menu-avatar'>
                {moreThanOneAccount && (
                    <span class={`current ${isCurrent ? 'is-current' : ''}`}>
                    <Icon icon="check-circle" alt="Current" />
                    </span>
                )}
                <Avatar
                    url={account.info.avatarStatic}
                    size="xxl"
                    onDblClick={async () => {
                    if (isCurrent) {
                        try {
                        const info = await masto.v1.accounts.fetch(
                            account.info.id,
                        );
                        console.log('fetched account info', info);
                        account.info = info;
                        store.local.setJSON('accounts', accounts);
                        reload();
                        } catch (e) {}
                    }
                    }}
                />
                <NameText
                    account={
                    moreThanOneAccount
                        ? {
                            ...account.info,
                            acct: /@/.test(account.info.acct)
                            ? account.info.acct
                            : `${account.info.acct}@${account.instanceURL}`,
                        }
                        : account.info
                    }
                    showAcct
                    onClick={() => {
                    if (isCurrent) {
                        states.showAccount = `${account.info.username}@${account.instanceURL}`;
                    } else {
                        store.session.set('currentAccount', account.info.id);
                        location.reload();
                    }
                    }}
                />
            </div>
        </MenuItem>
        );
    })}

    </>
  );
}

export default AccountsMenu;
